<template>
  <v-scale-transition>
    <kits-panel :kits-items="subscriberGroup">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Top Performance
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
    }
  },
  computed: {
    subscriberGroup: function () {
      const menu = [
        {
          icon: 'mdi-medal-outline',
          path: '/monthly_top_performance',
          title: 'Monthly',
          desc: 'Monthly Top 50 Performance',
        },
        {
          icon: 'mdi-medal-outline',
          path: '/daily_top_performance',
          title: 'Daily',
          desc: 'Daily Top 50 Performance',
        },
      ]

      return menu
    },
  },
}
</script>
